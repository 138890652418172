body {
  margin-top: 48px;
}

.header .navbar {
  background-color: #247594;
  a {
    color: #fff;
  }
}

.about-me {
  font-size: 20px;
  padding: 44px 0 44px 0;
  position: relative;
  color: #fff;
  max-width: 2400px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #17a2b8;

  h1 {
    font-size: 52px;
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 32px;
  }
}

.jobs {
  background-color: #a3d7af;
  padding: 44px 0 44px 0;
  position: relative;
  overflow: hidden;

  .life-line-mobile {
    border-left: #28a745 2px solid;
    position: relative;

    &:before {
      border: #28a745 8px solid;
      content: "";
      border-radius: 8px;
      display: block;
      width: 8px;
      position: absolute;
      left: -9px;
    }

    .content {
      position: relative;
      top: -5px;
    }
  }

  .life-line {
    border-left: #28a745 2px solid;
    position: relative;

    .content {
      position: relative;
      top: -5px;
    }
  }

  .container .row:last-of-type .life-line,
  .container .row:last-of-type .life-line-mobile {
    border-left: transparent 2px solid;
  }
}

.projects {
  padding: 44px 0;
}

.project {
  border-bottom: 1px #ccc solid;

   h4 {
     margin-bottom: 20px;
   }

  .image {
    width: 100%;
    max-width: 502px;
    height: auto;
  }

  &:last-of-type {
    padding-bottom: 0 !important;
    border-bottom: medium none;
  }
}

.skills {
  font-size: 20px;
  background-color: #28a745;
  padding: 44px 0 44px 0;
  position: relative;
  color: #fff;
  overflow: hidden;

  h2 {
    margin-bottom: 20px;
  }

  .background-container {
    background-color: #28a745;
    position: absolute;
    height: 30%;
    width: 200%;
    transform: rotate(1deg);
    z-index: -1;
    bottom: -66px;
    left: -40px;
  }
}

.contacts {
  padding: 44px 0 44px 0;
  color: #1f706d;

  h4 {
    text-transform: uppercase;
  }

  a {
    color: #1f706d;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:before {
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      display: inline-block;
      width: 20px;
      height: 16px;
      vertical-align: -4px;
    }
  }

  .email {
    a:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 height=%271em%27 viewBox=%270 0 512 512%27%3E%3C%21--%21 Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license %28Commercial License%29 Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%231f706d' d=%27M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z%27/%3E%3C/svg%3E");
    }
  }

  .phone {
    a:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%231f706d' d='M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z'/%3E%3C/svg%3E");
    }
  }

  .telegram {
    a:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 496 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%231f706d' d='M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z'/%3E%3C/svg%3E");
    }
  }

  h4 {
    font-weight: bold;
  }
}

.github {
  height: 240px;
  background-color: #333633;

  img {
    height: 53px;
    width: 53px;
  }
}

@media (min-width: 768px) {
  .jobs {
    .life-line-mobile {
      border-left: transparent 2px solid;

      &:before {
        border: medium none;
        content: "";
        border-radius: 8px;
        display: block;
        width: 8px;
        position: absolute;
        left: -9px;
      }

      .content {
        position: relative;
        top: 0;
      }
    }

    .life-line {
      border-left: #28a745 2px solid;
      position: relative;

      &:before {
        border: #28a745 8px solid;
        content: "";
        border-radius: 8px;
        display: block;
        width: 8px;
        position: absolute;
        left: -9px;
      }

      .content {
        position: relative;
        top: -5px;
      }
    }
  }
  
  @media (min-width: 1024px) {
    .about-me {
      h1 {
        max-width: 830px;
      }
    }
  }
}